import React from 'react'
import Devolver from '@/components/Devolver'
import Link from '@/components/Link'

const year = new Date().getFullYear()

const Footer = () => (
  <footer
    className={[
      'px-4',
      'py-16',
      'mx-auto',
      'mt-12',
      'overflow-hidden',
      'border-t',
      'border-red-700',
      'border-solid',
      'space-y-6',
      'max-w-screen-xl',
      'sm_px-6',
      'lg_px-8',
    ].join(' ')}
  >
    <div className="hover_scale-105">
      <Devolver
        onClick={() => (window.location = 'https://devolverdigital.com')}
        className="w-auto h-16 mx-auto text-white cursor-pointer transform hover_scale-105"
      />
    </div>
    <nav className="flex flex-wrap justify-center -mx-4 -my-2">
      <Link label="Support" url="https://support.devolverdigital.com" />
      <Link label="Privacy" url="https://legal.devolverdigital.com/privacy" />
      <Link label="Cookies" url="https://legal.devolverdigital.com/cookies" />
      <Link label="Terms" url="https://legal.devolverdigital.com/terms" />
      <Link label="Influencers" url="https://influencers.devolverdigital.com" />
      <Link
        label="Can I Monetize?"
        url="https://canipostandmonetizevideosofdevolvergames.com"
      />
    </nav>
    <p className="mt-8 text-xs font-medium text-center text-white leading-6">
      Copyright &copy; {year} Devolver Digital Inc. All Rights Reserved.
    </p>
  </footer>
)

export default Footer
