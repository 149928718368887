import useSWR from 'swr'
import fetcher from '@/lib/fetcher'

export function useCurrentUser() {
  const {data, mutate} = useSWR('/api/connect/user', fetcher)
  const user = data?.user
  return [user, {mutate}]
}

export function useUser(id) {
  const {data} = useSWR(`/api/connect/users/${id}`, fetcher, {
    revalidateOnFocus: false,
  })
  return data?.user
}
