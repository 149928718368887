import React from 'react'

const Container = ({children, ...props}) => (
  <main>
    <div className="px-4 mx-auto max-w-7xl sm_px-6 lg_lx-8" {...props}>
      {children}
    </div>
  </main>
)

export default Container
