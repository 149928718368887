import React from 'react'

const Link = ({label, url}) => (
  <div className="px-5 py-2">
    <a
      className="text-xs font-medium text-white underline uppercase leading-6 hover_text-black transition duration-150 ease-in-out"
      href={url}
    >
      {label}
    </a>
  </div>
)

export default Link
