import Head from 'next/head'
import Link from 'next/link'
import {useRouter} from 'next/router'
import React from 'react'
import Main from '@/components/Container'
import Footer from '@/components/Footer'
import Container from '@/components/Container'
import {signIn, signOut, useSession} from 'next-auth/client'
import {useCurrentUser} from '@/hooks/index'

// ===============================================

const debug = false
const baseTransition = ['transition', 'duration-150', 'ease-in-out']

// ===============================================

const Logo = ({label, ...props}) => (
  <div {...props}>
    <h1 className="font-black tracking-wider text-red-200 uppercase cursor-default">
      {label}
    </h1>
  </div>
)

const Item = ({href = '', label, current = false, ...props}) => {
  const router = useRouter()
  const curr = current ? current : router.pathname === href
  return (
    (<Link
      href={href}
      className={[
        'inline-flex',
        'items-center',
        'px-1',
        'text-sm',
        'font-medium',
        'leading-5',
        curr ? 'text-white' : 'text-red-800',
        curr ? 'hover_text-white' : 'hover_text-black',
        curr ? 'focus_text-white' : 'focus_text-black',
        'focus_outline-none',
      ]
        .concat(baseTransition)
        .join(' ')}
      {...props}>

      {label}

    </Link>)
  );
}

const Navigation = ({children}) => {
  const [session] = useSession()
  const [user, {mutate}] = useCurrentUser()
  const handleLogout = async () => {
    await fetch('/api/steam', {
      method: 'DELETE',
    })
    mutate(null)
    signOut({callbackUrl: '/'})
  }
  return (
    <nav className="border-b border-red-700 bg-red">
      <Container>
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <Logo label="DDBS" className="block lg_hidden" />
              <Logo label="Beta Squadron" className="hidden lg_block" />
            </div>
            <div className="hidden sm_-my-px sm_ml-6 space-x-8 sm_flex">
              {children}
              <Item href="/" label="Join" />
              {!session && (
                <Item
                  href="/api/auth/sign"
                  label="Sign In"
                  onClick={(e) => {
                    e.preventDefault()
                    signIn(null, {
                      callbackUrl: '/next',
                    })
                  }}
                />
              )}
              {session && <Item href="/next" label="Next Up" />}
              {session && (
                <Item onClick={() => handleLogout()} label="Sign Out" />
              )}
              {debug && <Item label={user?.steamId} />}
            </div>
          </div>
          <div className="flex ml-6">
            <Item href="https://linktr.ee/devolverdigital" label="Social" />
          </div>
        </div>
      </Container>
    </nav>
  )
}

// ===============================================

const Page = ({children}) => (
  <React.Fragment>
    <Head>
      <meta name="viewport" content="width=device-width initial-scale=1" />
      <meta charSet="utf-8" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <title>The Devolver Beta Squadron</title>
    </Head>
    <div className="min-h-screen bg-red-600">
      <Navigation />
      <div className="py-10">
        <Main>
          {children}
          <Footer />
        </Main>
      </div>
    </div>
  </React.Fragment>
)

export default Page
