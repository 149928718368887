import React from 'react'
import {Provider} from 'next-auth/client'
import Page from '@/layouts/Page'
import {init} from '@/lib/sentry'
import '../styles/globals.css'

init()

const BetaSquadron = ({Component, pageProps, err}) => {
  const {session} = pageProps
  return (
    <Provider options={{site: process.env.NEXTAUTH_URL}} session={session}>
      <Page>
        <Component {...pageProps} err={err} />
      </Page>
    </Provider>
  )
}

export default BetaSquadron
